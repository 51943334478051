.seperator {
  width: 100%;
}

.controls-wrapper {
  width: 100%;
}

.carousel-wrapper {
  width: 100%;
}

.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 0px violet;
}
.fyLrfx {
  display: none !important;
}

.dsyKYi {
  font-size: 1.2em !important;

  width: 35px !important;
  height: 35px !important;
  min-width: 35px !important;
  line-height: 35px !important;
}
.jsGVec {
  font-size: 1.2em !important;

  width: 35px !important;
  height: 35px !important;
  min-width: 35px !important;
  line-height: 35px !important;
}

.iDfeOS {
  margin: 0 0 !important;
}
